import * as React from 'react';
import LegalBody from '../../components/LegalBody';
import LegalHeader from '../../components/LegalHeader';
import DocumentHead from '../../components/DocumentHead';
import { privacyContent } from '../../content/index';
// @ts-ignore
import htmlContent from '../../content/privacy.html';

const PrivacyPage = () => {
  const { header } = privacyContent;
  return (
    <div
      data-testid="privacy-policy-page-container"
      className="md-900px:pt-9 pt-7"
    >
      <DocumentHead content={privacyContent.metadata} />

      <LegalHeader header={header.desktop} />

      <LegalBody
        htmlContent={htmlContent}
        buttonStyle="flex justify-center space-x-3 my-7 md:my-10"
      />
    </div>
  );
};

export default PrivacyPage;
